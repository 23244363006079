import {version} from '../package.json'

export const config = {
    mantainance: false,
    dev: false,
    bundle: "tcs.clienti-multicedi.com",
    version: version,
    acceptApiVersion: '2.0.0', 
    refreshTokenAdvance: 300, //seconds before expire
    apiKey: "c5c61363-2c79-11ee-805e-005056ad64d7",
    baseurl: "https://tcs-api.clienti-multicedi.com",
    defaultLanguage: { code: "it", label: "Italian" },
    languages: [
        { code: "gb", label: "English" },
        { code: "it", label: "Italian" },
    ],
    tab_channel: {
        device: "tcs.clienti-multicedi.com/device",
        mainTab: "tcs.clienti-multicedi.com/main-tab"
    },
    push_notification: {
        enabled: false,
        apiKey: "xxx",
        config: {  // WARNING: Remember to copy this params in /public/firebase-messaging-sw.js
            apiKey: "...",
            authDomain: "...",
            projectId: "...",
            storageBucket: "...",
            messagingSenderId: "...",
            appId: "...",
            measurementId: "..."
        }
    },
    restError: {
        BadRequest: "1000",
        PreconditionFailed: "6000",
        Forbidden: "8000",
    }
};